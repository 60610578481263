import AOS from 'aos'
import 'aos/dist/aos.css'
import 'flag-icon-css/css/flag-icon.css'
import type { AppProps } from 'next/app'
import React from 'react'
import '../lib/styles/globals.sass'

function MyApp({ Component, pageProps }: AppProps) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	;(global as any).__DEV_MODE__ = process.env.NODE_ENV === 'development'
	React.useEffect(() => {
		AOS.init({
			disable: 'mobile',
		})
	}, [])
	return <Component {...pageProps} />
}
export default MyApp
